body {
  overflow-x: hidden;
}

a {
  color: #0366d6;
}
code {
  color: #e01a76;
}
h1 {
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 32px;
}
h2 {
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 26px;
  margin-bottom: 0px;
}
h3 {
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 20px;
}
h4 {
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px;
}
h5 {
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
}
label {
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  padding-right: 5px;
}
.p-dialog {
  width: 75%;
  min-height: 75%;
  background-color: white;
  border-style: solid;
  border-radius: 5px;
}
.p-dialog-header {
  /*css for the header of the p-dialog*/
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
  color: #009dd9;
  padding: 10px;
}
.ui-table {
  margin-bottom: 15px;
}
.p-paginator-page {
  color: #0066b2 !important;
  margin: 10px !important;
}
#p-paginator-bottom {
  padding-bottom: 1rem !important;
}
.p-button {
  background-color: #1b6ec2 !important;
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #ffffff !important;
}
.p-picklist .p-picklist-buttons .p-button {
  width: 3rem;
}
.p-picklist-list-wrapper {
  width: 27rem;
}
.p-dropdown {
  display: flex !important;
}
.p-multiselect {
  display: flex !important;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #c1e2fb !important;
}

/*#region Components*/
.w2 {
  width: 2%;
}
.w4 {
  width: 4%;
}
.w5 {
  width: 5%;
}
.w6 {
  width: 6%;
}
.w8 {
  width: 8%;
}
.w10 {
  width: 10%;
}
.w12 {
  width: 12%;
}
.w14 {
  width: 14%;
}
.w15 {
  width: 15%;
}
.w16 {
  width: 16%;
}
.w17 {
  width: 17%;
}
.w18 {
  width: 18%;
}
.w20 {
  width: 20%;
}
.w21 {
  width: 21%;
}
.w22 {
  width: 22%;
}
.w23 {
  width: 23%;
}
.w30 {
  width: 30%;
}
.w31 {
  width: 31%;
}
.w35 {
  width: 35%;
}
.w40 {
  width: 40%;
}
.w50 {
  width: 50%;
}
.w64 {
  width: 64%;
}
.w65 {
  width: 65%;
}
.w70 {
  width: 70%;
}
.w72 {
  width: 72%;
}
.w73 {
  width: 73%;
}
.w76 {
  width: 76%;
}
.w80 {
  width: 80%;
}
.w100 {
  width: 100%;
}

.mr6 {
  margin-right: 6px;
}
.mr12 {
  margin-right: 12px;
}
.mr14 {
  margin-right: 14px;
}
.ml4 {
  margin-left: 4px;
}
.ml6 {
  margin-left: 6px;
}
.ml8 {
  margin-left: 8px;
}
.ml12 {
  margin-left: 12px;
}
.ml10 {
  margin-left: 10px;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}
.ml104 {
  margin-left: 104px;
}
.ml-78i {
  margin-left: -78px !important;
}
.mb0 {
  margin-bottom: 0px;
}
.mb4 {
  margin-bottom: 4px;
}
.mb6 {
  margin-bottom: 6px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mt8 {
  margin-top: 8px;
}
.mt12 {
  margin-top: 12px;
}
.mt24 {
  margin-top: 24px;
}

.pl0 {
  padding-left: 0px !important;
}
.pl8 {
  padding-left: 8px;
}

.w100i {
  width: 100px !important;
}

.fr {
  float: right;
}
.fl {
  float: left;
}
.tac {
  text-align: center;
}
.noPadding {
  padding: 0;
}
.noMarginBottom {
  margin-bottom: 0 !important;
}

.headerSection {
  border-left: 1px solid #e6e6e6;
}
.noPaddingLeft {
  padding-left: 0 !important;
}
.noPaddingRight {
  padding-right: 0 !important;
}
.divCenter {
  display: flex;
  align-items: center;
}
.divCenterFlex {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.divCentermt28 {
  display: flex;
  align-items: center;
  margin-top: 28px !important;
}
.divCentermt30 {
  display: flex;
  align-items: center;
  margin-top: 30px !important;
}
.divCenter2 {
  height: 32px;
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
.helpCursor {
  cursor: help;
}
.subTitle {
  color: #0066b2;
  font-size: 14px;
  font-weight: bold;
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.rigName {
  color: #2e7d32;
  font-size: 18px;
  font-weight: normal;
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.appName {
  color: #009dd9;
  font-size: 18px;
  font-weight: normal;
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.titleCreateNew {
  color: #0b2d71;
  font-size: 24px;
  font-weight: bold;
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.rigTime {
  font-size: 14px;
  color: #000000;
  font-weight: bold;
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.normalText {
  font-weight: normal;
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.boldTextFont {
  font-weight: bold;
  font-family: 'GothamBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.errorSpan {
  font-size: 14px;
  color: #710b04;
  font-style: italic;
}
.edtIcon {
  font-size: 24px;
  color: #0066b2;
  cursor: pointer;
}
.dltIcon {
  font-size: 24px;
  color: #bf2216;
  cursor: pointer;
}
/*#endregion*/
td div,
th div,
span {
  font-family: 'GothamBook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

@mixin icon-override_pi($icon) {
  background: none !important;
  font-family: 'primeicons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;

  &:before {
    content: $icon;
  }
}
p-multiselect {
  .p-multiselect-panel {
    .p-multiselect-header {
      .p-multiselect-close {
        .p-multiselect-close-icon {
          @include icon-override_pi('\e909');
        }
      }
    }
  }
}
.disabled {
  color: rgb(0, 0, 0, 0.2);
  pointer-events: none;
}

/*Angular Upgrade to 17 UI related changes*/

// Adjusting dropdown
.p-dropdown-items,
.p-multiselect-items,
.p-tieredmenu ul,
.p-contextmenu ul {
  padding: 0 !important;
  margin: 0 !important;
}

// Updating text size & style as old one
.p-component,
.p-inputtext {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 1rem;
  font-weight: normal;
}

// dialog box header
.p-dialog-header {
  padding: 1.5rem !important;
}

// weld log customize table
.p-picklist {
  ul {
    padding-left: 0;
  }
  .p-picklist-header {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    border-bottom: 0 none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-picklist-buttons .p-button {
    margin-bottom: 0.5rem;
  }
}
/*Angular Upgrade to 17 UI related changes*/
